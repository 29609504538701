<template>
  <div>
    <div v-if="toggle" class="columns  is-centered">
      <div class="column is-7 box">
        <b-message >
          Si hay algún problema con el correo USACH, contactarse con: <b><u>soporte@segic.usach.cl</u></b><br/>
          Para mayor información ingresar a <a class='is-underlined has-text-weight-bold' style='text-decoration-line: none;' href='https://www.usach.cl/correo-estudiantes' target='_blank'>
          https://www.usach.cl/correo-estudiantes</a>.
        </b-message>
      </div>
    </div>

    <div  class="columns is-centered is-multiline">
      <div class="column is-full has-text-centered">
        <b-button v-if="!displayContacts" type="is-primary is-light is-border-orange" icon-left="user" @click="displayContacts=!displayContacts">
          Contactos Registro Curricular FING
        </b-button>
        <b-button v-else type="is-primary is-light is-border-orange" icon-left="user" @click="displayContacts=!displayContacts">
          Ocultar Contactos de Registro Curricular FING
        </b-button>
      </div>
      <div v-if="displayContacts" class="column is-7">

        <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of collapses"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
            :aria-id="'contentIdForA11y5-' + index">
          <template #trigger="props">
            <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open">
              <p class="card-header-title is-flex is-justify-content-left subtitle is-3-desktop is-5-mobile has-text-secondary has-text-weight-bold is-family-secondary ">

                {{ collapse.titulo }}

              </p>

              <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'chevron-up' : 'chevron-down'">
                </b-icon>
              </a>

            </div>

          </template>

          <div class="card-content" v-for="(imagen,index) of collapse.imagenes" :key="index">
            <img v-bind:src=imagen class="responsive"><img>

          </div>

        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "LoginInfo",
  components: {},
  props:["toggle"],
  data() {
    return {
      displayContacts: false,
      isOpen: null,
      collapses: [
        {
          'titulo': 'CERTIFICADOS',
          'imagenes': [require('../../../public/GCC/contacts/1.png')]
        },
        {
          'titulo': 'PLANES Y PROGRAMAS',
          'imagenes': [require('/public/GCC/contacts/2.png')]
        }
      ]
    }
  },
  created() {
    document.title = "SAS | Iniciar Sesión";
  },

};
</script>
<style>

</style>