<template>
  <div
    class="container is-fullheight"
    style="padding: 2rem; background-color: #f2f2f2"
  >
    <div
      class="section"
      style="background-color: white; height: 100%"
      ref="newRequest"
    >
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>

      <!-- Titulo de la sección -->
      <div class="is-flex is-justify-content-flex-start">
        <h1 class="subtitle is-1 has-text-secondary">
          Crear Solicitud: {{ requestType.real_name }}
        </h1>
      </div>
      <div class="my-4">
        <b-tag v-if="requestType.role_type" type="is-secondary" size="is-large">
          {{ requestType.role_type }}
        </b-tag>
        <b-tag v-if="requestType.role_state" size="is-large" type="is-primary">
          {{ requestType.role_state }}
        </b-tag>
      </div>
      <div v-if="templates.length > 0" class="is-flex buttons mt-2">
        <b-button
          icon-left="eye"
          type="is-info is-light is-border-cornflowerblue"
          @click="openModalShowMultipleDocuments()"
          >Ver plantillas de la solicitud
        </b-button>
        <b-button
          icon-left="download"
          class="is-primary is-light is-border-darkgoldenrod"
          @click="downloadTemplates()"
        >
          Descargar plantillas
        </b-button>
      </div>
      <div v-if="requestTypeCount > requestTypeLimit">
        <b-message
          v-if="requestTypeCount > requestTypeLimit"
          type="is-danger"
          has-icon
          class="mb-2"
        >
          <p style="font-size: 1.09em">
            Has superado el límite máximo de solicitudes permitido ({{
              requestTypeLimit
            }}) entre el {{ semesterStart }} y el {{ semesterEnd }} en el SAS.
            <br />Tipo de solicitud: {{ requestType.real_name }}. <br />Si
            necesita realizar una nueva solicitud, debe hacerlo a través de la
            atención presencial o al correo centralizado de la unidad
            correspondiente.

            <br />Recuerda que puedes revisar tus solicitudes realizadas en el
            apartado
            <a href="/requests/my_requests" target="_blank"
              ><b>Mis Solicitudes</b></a
            >.
          </p>
        </b-message>
        <div class="column is-full has-text-centered">
          <b-button
            v-if="!displayContacts"
            type="is-primary is-light is-border-orange"
            icon-left="user"
            @click="displayContacts = !displayContacts"
          >
            Contactos de Registro Curricular FING
          </b-button>
          <b-button
            v-else
            type="is-primary is-light is-border-orange"
            icon-left="user"
            @click="displayContacts = !displayContacts"
          >
            Ocultar Contactos de Registro Curricular FING
          </b-button>
        </div>
        <ContactInformation v-if="displayContacts" />
      </div>

      <b-message
        v-else-if="requestTypeCount > 0"
        type="is-warning"
        has-icon
        class="mb-2"
      >
        <p style="font-size: 1.09em">
          Actualmente has realizado
          <b>{{ requestTypeCount }}/{{ requestTypeLimit }}</b> solicitud(es) del
          tipo {{ requestType.real_name }} entre el <b>{{ semesterStart }}</b> y
          el <b>{{ semesterEnd }}</b
          >. <br />
          Una vez superado el límite, no podrás presentar más solicitudes de
          este tipo a través del SAS hasta el siguiente periodo, sin embargo,
          podrás realizarlas por medio de los canales presenciales o el correo
          centralizado.<br />
          Recuerda que puedes revisar tus solicitudes realizadas en el apartado
          <a href="/requests/my_requests" target="_blank"
            ><b>Mis Solicitudes</b></a
          >.
        </p>
      </b-message>

      <!-- Separador -->
      <hr class="solid" />

      <b-steps
        v-if="requestTypeCount <= requestTypeLimit"
        v-model="activeStep"
        :animated="isAnimated"
        :has-navigation="false"
        :rounded="isRounded"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <b-step-item step="0" label="Información" :clickable="isStepsClickable">
          <!--titulo-->
          <div class="columns is-centered">
            <div class="column is-11">
              <div class="is-border-black mt-4">
                <div class="m-5">
                  <div class="title is-4 mt-2">Información de la solicitud</div>
                  <span
                    v-if="
                      requestType.long_description != '' &&
                      requestType.long_description != null
                    "
                    style="font-size: 1.2em"
                  >
                    {{ requestType.long_description }}
                  </span>
                  <p v-else style="font-size: 1.2em">
                    Bienvenid@<br />
                    Aquí puedes iniciar la solicitud {{ requestType.real_name
                    }}<br />
                    Recuerda rellenar y adjuntar los documentos y formularios en
                    caso de existir.<br />
                  </p>
                  <div
                    v-if="requestType.role_type === 'Planes y programas'"
                    class="mt-2"
                  >
                    <b-message type="is-warning" has-icon class="mb-2">
                      <p style="font-size: 1.2em">
                        Esta solicitud requerirá de un pago para poder ser
                        resuelta. Después de que su solicitud haya sido enviada,
                        esta será detenida para informarle de los detalles del
                        depósito de pago. <br />
                        Para mayor información, consulte el botón de Información
                        de Pagos. <br />
                      </p>
                    </b-message>
                    <div class="columns mt-0">
                      <div class="column is-9 pt-0"></div>
                      <div class="column is-3 has-text-right pt-0">
                        <b-button
                          class="is-gcc is-fullwidth is-border-black"
                          icon-left="shopping-cart"
                          @click="
                            isModalPaymentInfoOpen = !isModalPaymentInfoOpen
                          "
                        >
                          Información de Pagos
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="is-flex is-justify-content-center pt-2 mt-5">
            <!--botones-->
            <button
              class="button is-secondary mx-1"
              disabled
              @click="setPreviousStep"
            >
              Volver
            </button>
            <button
              class="button is-primary mx-1"
              type="submit"
              @click="setNextStep"
            >
              Siguiente
            </button>
          </div>
        </b-step-item>
        <!-- Step: Define States -->
        <b-step-item
          step="1"
          label="Formularios y documentación requerida"
          :clickable="isStepsClickable"
        >
          <div class="columns is-multiline my-4">
            <div class="column is-full pb-1 mb-0 mt-2">
              <h1 class="title is-4">Formularios a responder</h1>
            </div>
            <div class="column is-full has-text-centered pt-0">
              <span class="mb-0 pb-0"
                ><i> <b>(*) Campos obligatorios</b></i></span
              >
            </div>
          </div>

          <div>
            <div
              class="my-2"
              v-for="(form, form_index) in google_forms"
              :key="form_index"
            >
              <div class="field mt-4">
                <div class="is-size-5">
                  <b>{{ form_index + 1 }}. Formulario "{{ form.name }}" *</b>
                </div>
                <div class="my-2">
                  <AddFormResponseString
                    v-model="formToFill"
                    :form_id="google_forms[form_index].google_form_id"
                    :request_type_id="requestType.id"
                    @validationObserverRef="handleValidationObserverRef"
                  >
                  </AddFormResponseString>
                </div>
              </div>
            </div>
          </div>
          <span v-if="google_forms.length < 1">
            <label
              ><em
                >Este tipo de solicitud no requiere completar formularios.</em
              ></label
            >
          </span>
          <hr class="solid" />
          <h1 class="title mt-4 is-4">Documentación a adjuntar</h1>
          <div class="buttons">
            <b-button
              v-if="templates.length > 0"
              type="is-info is-light is-border-cornflowerblue"
              icon-left="eye"
              @click="openModalShowMultipleDocuments()"
              >Ver plantillas de la solicitud
            </b-button>
            <b-button
              v-if="templates.length > 0"
              icon-left="download"
              class="is-primary is-light is-border-darkgoldenrod"
              @click="downloadTemplates()"
            >
              Descargar plantillas
            </b-button>
          </div>

          <div>
            <div
              class="mb-2 mt-0"
              v-for="(document, document_index) in documents"
              :key="document_index"
            >
              <div class="field mt-4">

                <b-message v-if="document.description !== null" style="background-color: #f1f1f1;" type="is-gris">
                  <label class="label"
                  >{{ document_index + 1 }}. Documento "{{ document.name }}"
                    *</label
                  >
                  <div class="columns is-multiline">
                    <div class="column is-full has-text-dark">
                      <span>{{ document.description }}</span>
                    </div>
                  </div>
                  <b-field
                    class="file is-primary"
                    :class="{
                    'has-name': !!documentPlaceholder[document_index],
                  }"
                  >
                    <b-upload
                      v-model="documentPlaceholder[document_index]"
                      file-label
                      required
                    >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label"
                      >Adjuntar "{{ document.name }}"</span
                      >
                    </span>
                      <span
                        class="file-name has-background-white has-text-black"
                        v-if="documentPlaceholder[document_index].length != 0"
                      >
                      {{
                          shortenString(documentPlaceholder[document_index].name)
                        }}
                      {{
                          Math.round(
                            (documentPlaceholder[document_index].size * 100) /
                            1000000
                          ) / 100
                        }}MB
                    </span>
                    </b-upload>
                  </b-field>
                </b-message>
              </div>
            </div>
            <div class="columns mt-3" v-if="documents.length > 1">
              <div class="column is-full">
                <i>
                  <b>Extensiones permitidas:</b> '.pdf', '.doc', '.docx',
                  '.png', '.jpg', '.jpeg' <b>Tamaño máximo por documento:</b> 10MB</i
                >
              </div>
            </div>
          </div>

          <span v-if="documents.length < 1">
            <label
              ><em
                >Este tipo de solicitud no requiere documentos adjuntos.</em
              ></label
            >
          </span>
          <hr class="solid" />
          <div class="columns my-4">
            <div class="column has-text-left mb-0 mt-2">
              <h1 class="title is-4">Justificación de la solicitud</h1>
            </div>
          </div>

          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(createRequest)">
              <div>
                <div class="field mt-4">
                  <!-- Input Descripcion de la solicitud -->
                  <label class="label"
                    >Explique los motivos de su solicitud *

                    <b-tooltip
                      class="is-info"
                      label="
                                      Ej:
                                      La solicitud es para mi trabajo.
                                      Quiero cambiarme de carrera internamente.
                                      Necesito tomar la asignatura pero me falta un prerrequisito.
                                      Falte a un laboratorio de la asignatura X."
                      multilined
                    >
                      <b-icon icon="info-circle" type="is-secondary"></b-icon>
                    </b-tooltip>
                  </label>

                  <div class="control">
                    <ValidationProvider
                      rules="max:255|required"
                      v-slot="{ errors }"
                    >
                      <textarea
                        class="textarea"
                        v-model="inputDescription"
                        placeholder="Ingrese información adicional que considere necesaria"
                      ></textarea>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- Input Unidad de la solicitud  solo si tiene mas de 1 se muestra el campo-->
                <b-field
                  v-if="units.length != 1"
                  label="Unidad asociada a la solicitud *"
                >
                  <div>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <multiselect
                        v-model="inputUnit"
                        :options="units"
                        :custom-label="
                          (opt) => units.find((x) => x.id === opt.id).name
                        "
                        :multiple="false"
                        placeholder="Seleccione una unidad"
                        selectLabel="Presione para seleccionar"
                        selectedLabel="Seleccionado"
                        deselectLabel="Presione para deseleccionar"
                        track-by="id"
                        label="name"
                      >
                        <template v-slot:noOptions> No existen datos </template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </b-field>
              </div>

              <div class="is-flex is-justify-content-center pt-2">
                <b-button type="is-secondary mx-1" @click="setPreviousStep()"
                  >Volver</b-button
                >
                <b-button native-type="submit" type="is-primary mx-1 "
                  >Enviar solicitud</b-button
                >
              </div>
            </form>
          </ValidationObserver>
        </b-step-item>
      </b-steps>

      <b-modal
        v-model="isModalShowMultipleDocumentsActive"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <modal-show-multiple-documents
            :request-type-name-header="requestType.real_name"
            :requestTypeId="requestType.id"
            @close="props.close"
          >
          </modal-show-multiple-documents>
        </template>
      </b-modal>
      <b-modal
        v-model="isModalPaymentInfoOpen"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        width="90%"
      >
        <template #default="props">
          <PaymentInfoModal @close="props.close"> </PaymentInfoModal>
        </template>
      </b-modal>
      <!--      <b-modal
        v-model="isModalAddFormResponseStringActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
      >
        <template #default="props">
          <modal-add-form-response-string
            modal-header="Responder Formulario"
            :form_id="currentFormId"
            :request_type_id="requestType.id"
            @close="props.close"
            @clicked="storeTempForm"
          >
          </modal-add-form-response-string>
        </template>
      </b-modal>-->
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ModalShowMultipleDocuments from "../Documents/ModalShowMultipleDocuments";
import ModalAddFormResponseString from "../GoogleForms/ModalAddFormResponseString";
import AddFormResponseString from "../GoogleForms/AddFormResponseString.vue";
import { verifyFile } from "../../packs/utilities";

export default {
  name: "newRequest",
  props: [
    "infoRequest",
    "user",
    "requestTypeCount",
    "requestTypeLimit",
    "semesterStart",
    "semesterEnd",
  ],
  components: {
    ModalShowMultipleDocuments,
    ModalAddFormResponseString,
    AddFormResponseString,
    ContactInformation: () => import("../Tools/ContactInformation.vue"),
    PaymentInfoModal: () => import("../RequestType/PaymentInfoModal.vue"),
  },
  data: function () {
    return {
      isLoading: false,
      requestType: {
        real_name: "",
        description: "",
      },
      formToFill: [],
      templates: [],
      units: [],
      documents: [],
      google_forms: [],
      inputDescription: "",
      inputUnit: null,
      documentPlaceholder: [],
      formPlaceholder: [
        ["", ""],
        ["", ""],
      ],
      currentFormId: "",
      currentFormIndex: 0,
      formData: {},
      career: {
        agno_ingreso: null,
        codigo_carrera: null,
        codigo_mencion: null,
        nombre_carrera: null,
        nombre_especialidad: null,
        nombre_form: null,
        plan: null,
        semestre_ingreso: null,
        version_plan: null,
      },
      displayContacts: false,

      // Modals variables
      isModalShowMultipleDocumentsActive: false,
      isModalAddFormResponseStringActive: false,

      // Variable to record errors in posts or gets
      validationErrors: {},

      // b-steps' variables
      activeStep: 0,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: true,
      isProfileSuccess: false,

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",

      refObserver: null,
      isCardOpened: false,

      isModalPaymentInfoOpen: false,
    };
  },

  created() {
    document.title = "Nueva solicitud";
    this.isLoading = true;
    this.getUnits();
    this.getRequestType();
  },

  methods: {
    getRequestType() {
      const url = window.location.href;
      const requestTypeId = url.split("/").slice(-1)[0];

      axios
        .get("/request_types/" + requestTypeId + ".json")
        .then((response) => {
          this.requestType = response.data.request_type;
          this.requestType.role_type = response.data.role_type;
          this.requestType.role_state = response.data.role_state;
          this.templates = response.data.templates;
          this.documents = response.data.states[0].documents;
          this.google_forms = response.data.states[0].google_forms;
          this.fillFiles();
          this.fillForms();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.requestType = null;
          this.isLoading = false;
        });
    },

    getUnits() {
      axios
        .get("/units/get_units_requests.json")
        .then((response) => {
          this.units = response.data;
          if (this.units.length == 1) {
            this.inputUnit = this.units[0];
          }
        })
        .catch((error) => {
          console.log(error);
          this.units = [];
          this.isLoading = false;
        });
    },

    clearStartDate() {
      this.selectedStartDate = null;
    },

    fillForms() {
      let placeholder = [];
      for (const form in this.google_forms) {
        placeholder.push([this.google_forms[form].google_form_id, ""]);
      }
      this.formPlaceholder = placeholder;
    },

    fillFiles() {
      for (const document in this.documents) {
        this.documentPlaceholder.push([]);
      }
    },

    setFilesStep() {
      this.activeStep += 1;
    },

    setPreviousStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    setNextStep() {
      this.activeStep += 1;
    },
    handleValidationObserverRef(ref) {
      this.refObserver = ref;
    },
    openModalAddFormResponseString(index) {
      this.currentFormId = this.google_forms[index].google_form_id;
      this.currentFormIndex = index;
      this.isModalAddFormResponseStringActive = true;
    },

    storeTempForm(value) {
      //busca la carrera que se selecciono
      let item_title;
      for (let i = 0; i < value.length; i++) {
        item_title = value[i].item_title.toLowerCase();
        if (
          item_title === "selecciona tu carrera*" ||
          item_title === "selecciona tu carrera    "
        ) {
          if (value[i].student_careers != undefined) {
            let career = value[i].student_careers.find(
              (career) => career.nombre_form === value[i].item_response
            );
            if (career == undefined) {
              this.career.nombre_form = value[i].item_response;
            } else {
              this.career = career;
            }
          } else {
            this.career.nombre_form = value[i].item_response;
          }
        }
      }
      value = JSON.stringify(value);
      this.formPlaceholder[this.currentFormIndex][1] = value;
    },

    openModalShowMultipleDocuments() {
      this.isModalShowMultipleDocumentsActive = true;
    },

    deleteFile(index) {
      this.documentPlaceholder.splice(index, 1);
      if (index >= this.documentPlaceholder.length) {
        this.documentPlaceholder.push([]);
      } else {
        this.documentPlaceholder.splice(index, 0, []);
      }
    },

    async validateForms() {
      let validForm = await this.refObserver.validate().then((success) => {
        return success;
      });
      if (!validForm) return false;
      if (this.formPlaceholder.length > 0) {
        for (const form of this.formPlaceholder) {
          if (form[1] == "") {
            return false;
          }
        }
      }
      return true;
    },
    validateFiles() {
      if (this.documentPlaceholder.length > 0) {
        for (const doc of this.documentPlaceholder) {
          if (doc.length == 0) {
            return "Falta subir un archivo";
          } else {
            /*VERIFICACION FRON ARCHIVOS*/
            let error = verifyFile(doc);
            if (error) {
              return "Error en archivo " + doc.name + ": " + error;
            }
          }
        }
      }
      return "";
    },

    getMonth(date) {
      return date.getMonth() + 1;
    },

    async createRequest() {
      this.storeTempForm(this.formToFill);
      this.isLoading = true;
      let is_valid_forms = await this.validateForms();
      let has_error_file = this.validateFiles(); //string with error
      if (!is_valid_forms) {
        this.isLoading = false;
        this.$buefy.dialog.alert({
          title: "Faltan formularios",
          message:
            "Debe completar todos los formularios para ingresar la solicitud",
          type: "is-info",
          hasIcon: true,
          icon: "exclamation-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else if (has_error_file) {
        //si hay une error
        this.isLoading = false;
        this.$buefy.dialog.alert({
          title: "Error en archivos",
          message: has_error_file,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.formData = new FormData();
        this.formData.append("career", JSON.stringify(this.career));
        this.formData.append("description", this.inputDescription);
        this.formData.append("unit_id", this.inputUnit.id);
        this.formData.append("request_type_id", this.requestType.id);
        this.documentPlaceholder.forEach((file) => {
          this.formData.append("files[]", file);
        });
        this.formPlaceholder.forEach((form) => {
          this.formData.append("forms[]", form);
        });

        axios
          .post("/requests.json", this.formData)
          .then((response) => {
            let start_date = new Date(response.data.start_date);
            let hour =
              start_date.getHours() < 10
                ? "0" + start_date.getHours()
                : start_date.getHours();
            let minutes =
              start_date.getMinutes() < 10
                ? "0" + start_date.getMinutes()
                : start_date.getMinutes();
            this.text =
              "La solicitud se ha creado exitosamente, detalles a continuación: <br><b>Tipo de solicitud:</b> " +
              this.requestType.name +
              "<br><b>Código de solicitud:</b> " +
              response.data.code +
              "<br><b>Fecha de creación:</b> " +
              start_date.getDate() +
              "/" +
              this.getMonth(start_date) +
              "/" +
              start_date.getFullYear() +
              "<br><b>Hora de creación:</b> " +
              hour +
              ":" +
              minutes;
            if (response.status == 201) {
              if (
                this.user &&
                this.user.alt_email &&
                !this.user.corporate_email_active
              )
                this.text =
                  this.text +
                  '<br> <i class="fas fa-envelope "></i> Considere también que se ha enviado un mensaje a su correo alternativo.';
              else
                this.text =
                  this.text +
                  '<br> <i class="fas fa-envelope "></i> Considere también que se ha enviado un mensaje a su correo institucional.';
            } else {
              this.text =
                this.text +
                "<br>Por algún problema en el servidor no ha sido posible enviar un mensaje a su correo, asegurese de anotar los datos.";
            }

            this.$buefy.dialog.confirm({
              title: "Listo",
              message: this.text,
              type: "is-success",
              hasIcon: true,
              icon: "check-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
              canCancel: false,
              onConfirm: () => {
                window.location.href = "/requests/" + response.data.id;
              },
            });
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.validationErrors = error.response.data;
            console.log(this.validationErrors);
            var elmnt = document.getElementById("app");
            elmnt.scrollIntoView();
            this.$refs.newRequest.scrollTop = 0;
            this.$buefy.dialog.alert({
              title: "Error",
              message: this.validationErrors.error,
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            this.isLoading = false;
          });
      }
    },

    shortenString(str) {
      const length = 17;
      if (str.length > length) {
        return str.substring(0, length) + "...";
      } else {
        return str;
      }
    },

    async downloadTemplates() {
      let vm = this;
      vm.isLoading = true;
      for (let i = 0; i < vm.templates.length; i++) {
        await axios
          .get(
            "/documents/download_file/" + vm.templates[i].id_document_drive,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            let info = response.headers["content-disposition"];
            let name = info.split('"')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
          })
          .catch((e) => {
            console.log(e);
            vm.$buefy.dialog.alert({
              title: "Error",
              message: "Fallo la descarga.",
              type: "is-danger",
            });
          });
      }
      vm.isLoading = false;
    },
  },
};
</script>
